<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="pub-tt m15">
          <h5 class="bt">
            <img
              src="../assets/images/equip_01.png"
              class="ico"
              alt=""
            />项目配置
          </h5>
          <div class="dix"></div>
        </div>
        <div class="mb20">
          <button class="modifybtn" @click="onEdit()" v-show="!editPop">
            编辑修改
          </button>
        </div>
        <div class="top-bar p025">
          <el-form
            ref="form"
            class="top-form"
            :model="project"
            label-width="120px"
          >
            <el-row :gutter="0">
              <el-col :span="6">
                <el-form-item label="项目名称:">
                  <el-input
                    v-model="project.name"
                    placeholder="请输入用户名"
                    :disabled="!editPop"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="管理员:">
                  <el-select
                    v-model="project.adminUserId"
                    placeholder="请选择用户"
                    :disabled="!editPop"
                    @change="changeAdmin(project.adminUserId)"
                  >
                    <el-option
                      v-for="item in users"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="电话:" disabled="true">
                  <label>{{ project.adminUserPhone }}</label>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="项目路由:">
                  <label>{{ project.domain }}</label>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="0">
              <el-col :span="12">
                <el-form-item label="详细地址:">
                  <el-input
                    v-model="project.address"
                    placeholder="请输入详细地址"
                    :disabled="!editPop"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <div class="flex">
                  <div class="flex-1">
                    <el-form-item label="建筑面积(m2):">
                      <el-input
                        v-model="project.floorSpace"
                        placeholder="请输入建筑面积"
                        :disabled="!editPop"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :span="4" :offset="2">
                <div class="flex">
                  <button class="anniu anniu1" @click="toEquip">
                    设备管理
                  </button>
                  <button class="anniu anniu2" @click="toWarn">告警管理</button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="file-sec">
          <el-upload
            class="avatar-uploader"
            :on-exceed="(files, fileList) => handleExceed(files, fileList, 1)"
            :class="noneBtnDealImg ? 'disUoloadSty' : ''"
            :action="upload.uploadUrl"
            :headers="upload.headers"
            :fileList="fileList"
            :limit="1"
            :on-success="
              (res, file, fileList) =>
                handleAvatarSuccess(res, file, fileList, 'img')
            "
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file, fileList)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <!-- <p class="fz">点击上传/修改图片</p>             -->
          </el-upload>
        </div>
        <div class="bot-btn" v-show="editPop">
          <button class="anniu cancel" @click="editPop = false">取消</button>
          <button class="anniu confirm" @click="onSubmit">保存</button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" modal-append-to-body>
      <div class="pop pop-adduser">
        <div class="toptt">
          <span class="backbtn">图片预览</span>
          <i class="el-icon-close close" @click="dialogVisible = false"></i>
          <div class="dix"></div>
        </div>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { userList } from "@/api/user";
import { projectList, editProject } from "@/api/project";
export default {
  data() {
    return {
      project: {},
      editPop: false,
      imageUrl: "",
      projectId: null,
      projects: [],
      fileList: [],
      disabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
      noneBtnDealImg: false,
      users: [],
      upload: {
        // 设置上传的请求头部
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        // 上传的地址
        uploadUrl: "/apis/002-uploadFileForJson",
      },
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
  },
  created() {
    this.projectId = Number(sessionStorage.getItem("projectId"));
    this.projects = JSON.parse(sessionStorage.getItem("projectList"));
    for (let i = 0; i < this.projects.length; i++) {
      if (this.projects[i].id === this.projectId) {
        this.project = this.projects[i];
      }
    }
    this.getUserList();
  },
  mounted() {},
  watch: {},
  methods: {
    beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = res.rows[0].url;
      this.noneBtnDealImg = fileList.length >= 1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.dialogImageUrl = "";
      this.fileList = [];
      this.imageUrl = "";
      this.noneBtnDealImg = fileList.length >= 1;
    },
    //进入编辑模式
    onEdit() {
      this.editPop = true;
    },
    //更改项目
    onSubmit() {
      this.$confirm("此操作将更改项目信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.imageUrl == "") {
            this.$message.warning("请上传图片!");
          } else {
            this.project.pic = this.imageUrl;
          }
          editProject(this.project).then((res) => {
            if (res.data.code == 0) {
              this.getProjectList();
              this.editPop = false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //获取项目列表
    getProjectList() {
      let param = {
        pageNum: 1,
        pageSize: 9999999,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
      };
      projectList(param).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.setItem("projectList", JSON.stringify(res.data.rows));
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //获取用户列表
    getUserList() {
      let param = {
        pageNum: 1,
        pageSize: 999999,
        // projectIds:Number(sessionStorage.getItem("projectId"))
      };
      userList(param).then((res) => {
        if (res.data.code == 0) {
          this.users = res.data.rows;
        }
      });
    },
    //更改管理员
    changeAdmin(userId) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === userId) {
          this.project.adminUserPhone = this.users[i].phone;
        }
      }
    },
    //跳转到设备管理
    toEquip() {
      this.$router.push("/set-equip");
    },

    //跳转到告警管理
    toWarn() {
      this.$router.push("/set-warn");
    },
  },
};
</script>

<style scoped>
.top-form .anniu {
  font-size: 14px;
  color: #ccd9e5;
  padding: 0 12px;
  line-height: 38px;
  height: 38px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  border: none;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
  justify-content: center;
  margin: 0 0 0 15px;
}

.top-form .anniu::before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.top-form .anniu1 {
  background: url(../assets/images/set_15.png) no-repeat center;
  background-size: 100% 100%;
}

.top-form .anniu1::before {
  background: url(../assets/images/set_17.png) no-repeat center;
  background-size: contain;
}

.top-form .anniu2 {
  background: url(../assets/images/set_16.png) no-repeat center;
  background-size: 100% 100%;
}

.top-form .anniu2::before {
  background: url(../assets/images/set_18.png) no-repeat center;
  background-size: contain;
}

.top-form .anniu:hover {
  opacity: 0.85;
}

.modifybtn {
  font-size: 15px;
  color: #fff;
  width: 95px;
  height: 40px;
  background: url(../assets/images/equip_05.png) no-repeat center;
  background-size: contain;
  border: none;
  cursor: pointer;
  margin-left: 30px;
}

.modifybtn:hover {
  opacity: 0.85;
}

.file-sec {
  width: 680px;
  height: 430px;
  background: url(../assets/images/set_20.png) no-repeat center;
  background-size: 100% 100%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-sec .icon {
  width: 90px;
  height: 90px;
}

.file-sec .fz {
  font-size: 14px;
  color: #876ec0;
  text-align: center;
  margin-top: 10px;
}

.bot-btn {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.bot-btn .anniu {
  font-size: 15px;
  color: #fff;
  margin: 0 30px;
  width: 95px;
  height: 40px;
  border: none;
  cursor: pointer;
  border: none;
  cursor: pointer;
}

.bot-btn .confirm {
  background: url(../assets/images/pop_03.png) no-repeat center;
  background-size: 100% 100%;
}

.bot-btn .cancel {
  background: url(../assets/images/pop_05.png) no-repeat center;
  background-size: 100% 100%;
}

.bot-btn .anniu:hover {
  opacity: 0.85;
}
::v-deep .disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
